import React from "react";
import Image1 from "./images/Vector 244.svg";
import Image2 from "./images/Abou-us-Video.svg";

function Section3() {
  return (
    <section class="pl-[100px] pr-[100px] pb-[50px] m-0 relative max-md:pl-[32px]  max-md:pr-[32px]">
      <img src={Image1} class="h-189.5 w-780 top-0 left-0 absolute"></img>
      <div class="flex pt-[220px] flex-col md:flex-row max-md:pt-[150px] justify-between gap-[30px]">
        <div className="w-1/2 max-md:w-full ">
          <div class=" h-[5px] w-[69px] bg-[#FF9900]"></div>
          <div class="mt-[20px]">
            <h1 className="text-[35px] leading-[55px]">
              Leading companies trust us
            </h1>
            <h1 className="text-[35px] leading-[55px] font-bold">
              to develop software
            </h1>
          </div>
          <div class="mt-[30px] ">
            <p class="text-[18px]  leading-[36px] text-[#718096] max-md:w-auto ">
              We{" "}
              <span class="text-[#FF9900]">
                add development capacity to tech
              </span>
              teams. Our value isn’t limited to building teams but is equally
              distributed across the project lifecycle. We are a custom software
              development company that guarantees the successful delivery of
              your project.
            </p>
            <p class="mt-[30px] text-[#FF9900] text-[18px] max-md:mt-[10px]">
              See more Informations
            </p>
          </div>
        </div>
        <div class="w-1/2 max-md:w-full">
          <img src={Image2} class="h-460 w-600 rounded-[20px]"></img>
        </div>
      </div>
    </section>
  );
}

export default Section3;
