import React from "react";

function Section4() {
  return (
    <section class=" bg-[#f5f3f3] relative p-5 pb-8">
      <div className="w-[69px] h-[5px] bg-[#FF9900] relative mt-5 mx-auto" />
      <p className="top-2 left-0 [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-dark-blue text-[35px] text-center tracking-[0] leading-[55px]">
        <span className="[font-family:'Inter-Regular',Helvetica] font-normal text-[#263238] text-[35px] tracking-[0] leading-[55px]">
          Our design and
          <br />
        </span>
        <span className="[font-family:'Inter-Bold',Helvetica] font-bold">
          development approach
        </span>
      </p>

      <div className="justify-center mt-10">
        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
          <div className="flex flex-col items-stretch w-6/12 max-md:w-full max-md:ml-0">
            <div className="flex grow flex-col justify-center items-stretch max-md:max-w-full max-md:mt-6">
              <div className="shadow-2xl bg-white hover:bg-amber-300 flex flex-col justify-center items-stretch px-9 py-11 rounded-3xl max-md:max-w-full max-md:px-5">
                <div className="flex justify-between gap-5 mt-2.5 items-start max-md:max-w-full max-md:flex-wrap">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/5fc36e9726f0a6792046260c0f981f01505e89b4e841b46d1d1f8f6c815a8d72?"
                    className="aspect-[1.05] object-contain object-center w-[59px] overflow-hidden shrink-0 max-w-full"
                  />
                  <span className="items-stretch self-stretch flex grow basis-[0%] flex-col max-md:max-w-full">
                    <div className="text-amber-500 text-xl font-semibold leading-7 max-md:max-w-full">
                      UX Driven Engineering
                    </div>
                    <div className="text-slate-600 text-sm leading-6 mt-2.5 max-md:max-w-full">
                      Unlike other companies, we are a{" "}
                      <span className="text-slate-600">UX first</span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-stretch w-6/12 ml-5 max-md:w-full max-md:ml-0">
            <div className="flex grow flex-col justify-center items-stretch max-md:max-w-full max-md:mt-6">
              <div className="shadow-2xl bg-white hover:bg-amber-300 flex flex-col justify-center items-stretch px-9 py-11 rounded-3xl max-md:max-w-full max-md:px-5">
                <div className="flex justify-between gap-5 mt-2.5 items-start max-md:max-w-full max-md:flex-wrap">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/121aefb57e87e247c913b542db87598e73adae78f7c901d8b1b5ffc04ee3e855?"
                    className="aspect-[1.05] object-contain object-center w-[59px] overflow-hidden shrink-0 max-w-full"
                  />
                  <span className="items-stretch self-stretch flex grow basis-[0%] flex-col max-md:max-w-full">
                    <div className="text-amber-500 text-xl font-semibold leading-7 max-md:max-w-full">
                      Developing Shared Understanding
                    </div>
                    <div className="text-slate-600 text-sm leading-6 bg-clip-text bg-[linear-gradient(225deg,#68DBF2_0.01%,#509CF5_100%)] mt-2.5 max-md:max-w-full">
                      Unlike other companies, we are a UX first
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-center mt-5">
        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
          <div className="flex flex-col items-stretch w-6/12 max-md:w-full max-md:ml-0">
            <div className="flex grow flex-col justify-center items-stretch max-md:max-w-full max-md:mt-6">
              <div className="shadow-2xl bg-white hover:bg-amber-300 flex flex-col justify-center items-stretch px-9 py-11 rounded-3xl max-md:max-w-full max-md:px-5">
                <div className="flex justify-between gap-5 mt-2.5 items-start max-md:max-w-full max-md:flex-wrap">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/5fc36e9726f0a6792046260c0f981f01505e89b4e841b46d1d1f8f6c815a8d72?"
                    className="aspect-[1.05] object-contain object-center w-[59px] overflow-hidden shrink-0 max-w-full"
                  />
                  <span className="items-stretch self-stretch flex grow basis-[0%] flex-col max-md:max-w-full">
                    <div className="text-amber-500 text-xl font-semibold leading-7 max-md:max-w-full">
                      UX Driven Engineering
                    </div>
                    <div className="text-slate-600 text-sm leading-6 mt-2.5 max-md:max-w-full">
                      Unlike other companies, we are a{" "}
                      <span className="text-slate-600">UX first</span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-stretch w-6/12 ml-5 max-md:w-full max-md:ml-0">
            <div className="flex grow flex-col justify-center items-stretch max-md:max-w-full max-md:mt-6">
              <div className="shadow-2xl bg-white hover:bg-amber-300 flex flex-col justify-center items-stretch px-9 py-11 rounded-3xl max-md:max-w-full max-md:px-5">
                <div className="flex justify-between gap-5 mt-2.5 items-start max-md:max-w-full max-md:flex-wrap">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/121aefb57e87e247c913b542db87598e73adae78f7c901d8b1b5ffc04ee3e855?"
                    className="aspect-[1.05] object-contain object-center w-[59px] overflow-hidden shrink-0 max-w-full"
                  />
                  <span className="items-stretch self-stretch flex grow basis-[0%] flex-col max-md:max-w-full">
                    <div className="text-amber-500 text-xl font-semibold leading-7 max-md:max-w-full">
                      Developing Shared Understanding
                    </div>
                    <div className="text-slate-600 text-sm leading-6 bg-clip-text bg-[linear-gradient(225deg,#68DBF2_0.01%,#509CF5_100%)] mt-2.5 max-md:max-w-full">
                      Unlike other companies, we are a UX first
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-center mt-5">
        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
          <div className="flex flex-col items-stretch w-6/12 max-md:w-full max-md:ml-0">
            <div className="flex grow flex-col justify-center items-stretch max-md:max-w-full max-md:mt-6">
              <div className="shadow-2xl bg-white hover:bg-amber-300 flex flex-col justify-center items-stretch px-9 py-11 rounded-3xl max-md:max-w-full max-md:px-5">
                <div className="flex justify-between gap-5 mt-2.5 items-start max-md:max-w-full max-md:flex-wrap">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/5fc36e9726f0a6792046260c0f981f01505e89b4e841b46d1d1f8f6c815a8d72?"
                    className="aspect-[1.05] object-contain object-center w-[59px] overflow-hidden shrink-0 max-w-full"
                  />
                  <span className="items-stretch self-stretch flex grow basis-[0%] flex-col max-md:max-w-full">
                    <div className="text-amber-500 text-xl font-semibold leading-7 max-md:max-w-full">
                      UX Driven Engineering
                    </div>
                    <div className="text-slate-600 text-sm leading-6 mt-2.5 max-md:max-w-full">
                      Unlike other companies, we are a{" "}
                      <span className="text-slate-600">UX first</span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-stretch w-6/12 ml-5 max-md:w-full max-md:ml-0">
            <div className="flex grow flex-col justify-center items-stretch max-md:max-w-full max-md:mt-6">
              <div className="shadow-2xl bg-white hover:bg-amber-300 flex flex-col justify-center items-stretch px-9 py-11 rounded-3xl max-md:max-w-full max-md:px-5">
                <div className="flex justify-between gap-5 mt-2.5 items-start max-md:max-w-full max-md:flex-wrap">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/121aefb57e87e247c913b542db87598e73adae78f7c901d8b1b5ffc04ee3e855?"
                    className="aspect-[1.05] object-contain object-center w-[59px] overflow-hidden shrink-0 max-w-full"
                  />
                  <span className="items-stretch self-stretch flex grow basis-[0%] flex-col max-md:max-w-full">
                    <div className="text-amber-500 text-xl font-semibold leading-7 max-md:max-w-full">
                      Developing Shared Understanding
                    </div>
                    <div className="text-slate-600 text-sm leading-6 bg-clip-text bg-[linear-gradient(225deg,#68DBF2_0.01%,#509CF5_100%)] mt-2.5 max-md:max-w-full">
                      Unlike other companies, we are a UX first
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section4;
