import React, { useState, useEffect } from "react";
import logo from "./Home/images/right-chevron.png";

function Navbar() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [selectedPage, setSelectedPage] = useState("home");
  const [navbarColor, setNavbarColor] = useState("bg-transparent");

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setNavbarColor("bg-white");
      } else {
        setNavbarColor("bg-transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMobileMenuClick = () => {
    setMobileMenu(!mobileMenu);
    setNavbarColor(mobileMenu ? "bg-transparent" : "bg-white"); // Change to white when menu is opened
  };

  return (
    <>
      <nav
        className={`px-5 md:px-[104px] py-5 md:py-[20px] fixed top-0 left-0 right-0 z-50 ${navbarColor}`}
      >
        <div className=" mx-auto">
          <div className="flex justify-between items-center">
            <div>
              <img src={logo} className="h-20 w-20" alt="Logo" />
            </div>

            <div className="hidden md:flex items-center space-x-[30px] lg:space-x-[60px]">
              <a
                href="#"
                className={`${
                  selectedPage === "home" ? "text-orange font-semibold" : ""
                } text-gray-700 hover:text-gray-900`}
                onClick={() => setSelectedPage("home")}
              >
                Home
              </a>
              <a
                href="#"
                className={`${
                  selectedPage === "projects" ? "text-orange font-semibold" : ""
                } text-gray-700 hover:text-gray-900`}
                onClick={() => setSelectedPage("projects")}
              >
                Projects
              </a>
              <a
                href="#"
                className={`${
                  selectedPage === "aboutUs" ? "text-orange font-semibold" : ""
                } text-gray-700 hover:text-gray-900`}
                onClick={() => setSelectedPage("aboutUs")}
              >
                About Us
              </a>
              <a href="#">
                <button className="text-white rounded-[8px] font-semibold text-[18px] bg-[#263238] px-[56px] py-[18px]">
                  Contact
                </button>
              </a>
            </div>

            <div className="md:hidden flex items-center">
              <button
                className="mobile-menu-button"
                onClick={handleMobileMenuClick}
              >
                <svg
                  className="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div
          className={`mobile-menu ${
            mobileMenu ? "" : "hidden"
          } md:hidden pl-5 space-y-[24px] pt-[24px]`}
        >
          <a
            href="#"
            className={`${
              selectedPage === "home" ? "text-orange font-semibold" : ""
            } block text-[18px] hover:bg-gray-200 text-black`}
            onClick={() => setSelectedPage("home")}
          >
            home
          </a>
          <a
            href="#"
            className={`${
              selectedPage === "projects" ? "text-orange font-semibold" : ""
            } block text-[18px] hover:bg-gray-200 text-black`}
            onClick={() => setSelectedPage("projects")}
          >
            Projects
          </a>
          <a
            href="#"
            className={`${
              selectedPage === "aboutUs" ? "text-orange font-semibold" : ""
            } block text-[18px] hover:bg-gray-200 text-black`}
            onClick={() => setSelectedPage("aboutUs")}
          >
            About Us
          </a>
          <a href="#" className="block">
            <button className="text-white rounded-[8px] font-semibold text-[18px] bg-[#263238] px-[56px] py-[18px]">
              Contact
            </button>
          </a>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
