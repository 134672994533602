import React from "react";
import logo from "./images/right-chevron-white.png";

function Footer() {
  return (
    <div className="flex flex-col pt-[5px] pb-[5px] bg-zinc-800">
      <div className="flex flex-col self-center w-full max-w-[1146px] max-md:max-w-full">
        <div className="flex gap-[800px] right-0 pr-20 w-full max-md:flex-wrap max-md:pr-5 max-md:max-w-full">
          <div className="flex items-center justify-center py-0.5 text-4xl font-bold text-white whitespace-nowrap leading-[51.88px]">
            <img src={logo} className="h-20 w-20"></img>
            <span>RAY TECH</span>
          </div>
          {/* <div className="flex gap-5 py-px pl-20 my-auto">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/3c4ce1de6ac8ab6d9945e8c50a3ff66d30e475c72c257a58fb4787340b56a96d?"
              className="shrink-0 aspect-[1.05] w-[22px]"
            />
          </div> */}
        </div>
        <div className="shrink-0 self-end mt-[5px] max-w-full h-px border border-solid bg-neutral-600 border-neutral-600 w-[807px]" />
      </div>
      <div className="flex flex-col items-center px-20 mt-[5px] w-full max-md:px-5 max-md:max-w-full">
        <div className="w-full max-w-[1243px] max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[30%] max-md:ml-0 max-md:w-full">
              <div className="text-sm leading-8 text-white max-md:mt-10">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.{" "}
              </div>
            </div>
            <div className="flex flex-col ml-5 w-[70%] max-md:ml-0 max-md:w-full">
              <div className="grow max-md:mt-10 max-md:max-w-full">
                <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                  <div className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col grow pt-2 pb-7 font-medium text-white capitalize max-md:mt-10">
                      <div className="text-base leading-8">Home</div>
                      <div className="mt-4 text-sm max-md:mt-10">
                        Service we offer
                      </div>
                      <div className="mt-2 text-sm">Our Approach</div>
                      <div className="mt-2 text-sm">Our Tech Stack</div>
                    </div>
                  </div>
                  <div className="flex flex-col ml-5 w-[30%] max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col grow pt-2 pb-16 text-l font-medium text-white capitalize max-md:mt-10">
                      <div className="text-base leading-8">Projects</div>
                      <div className="mt-4 text-sm max-md:mt-10">
                        App Projects
                      </div>
                      <div className="mt-2 text-sm">Web Projects</div>
                    </div>
                  </div>
                  <div className="flex flex-col ml-5 w-[45%] max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col grow px-px pt-2 text-sm leading-8 text-white capitalize max-md:mt-10">
                      <div className="self-start ml-4 text-base font-medium leading-8 max-md:ml-2.5">
                        Contact Us
                      </div>
                      <div className="justify-center px-2 py-2 mt-4 rounded-3xl border border-solid border-neutral-500 max-md:px-2">
                        1700 W Blancke St, kiyev <br />
                        port south USA, American
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shrink-0 mt-[0px] max-w-full h-px border border-solid bg-neutral-600 border-neutral-600 w-[1243px] max-md:mt-10" />
        <div className="mt-1 text-l leading-8 text-white capitalize max-md:max-w-full">
          <span className="text-white">Copyright © </span>
          <span className="text-white">RAY TECH</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
