import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Home/Footer";
import Hero from "../components/Home/Hero";
import Section3 from "../components/Home/Section3";
import Section2 from "../components/Home/Section2";
import Section4 from "../components/Home/Section4";
import Section5 from "../components/Home/Section5";

function Home() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <div>
        {" "}
        <Footer />
      </div>
    </div>
  );
}

export default Home;
