import React from "react";
import Image1 from "./images/Vector 245.svg";
import Image2 from "./images/heroimage.svg";
import Image3 from "./images/Vector 246.svg";

function Hero() {
  return (
    <section className="pl-[100px] pr-[100px] pb-[159px] m-0 relative max-md:pl-[32px]  max-md:pr-[32px]">
      <img src={Image1} className="h-159 w-362.5 top-0 right-0 absolute"></img>
      <div className="flex pt-[150px] flex-col md:flex-row max-md:pt-[150px] justify-between gap-[30px]">
        <div className="w-1/2 max-md:w-full ">
          <div className=" h-[5px] w-[69px] bg-[#FF9900]"></div>
          <div className="mt-[20px]">
            <h1 className="text-[35px] leading-[55px]">
              Great <span className="text-[#FF9900]">Product</span> is
            </h1>
            <h1 className="text-[35px] leading-[55px]  mt-[0px] font-bold">
              built by great
              <span className="text-[#FF9900]"> teams</span>
            </h1>
          </div>
          <div className="mt-[10px] ">
            <p className="text-[18px]  leading-[36px] text-[#718096] max-md:w-auto ">
              We help build and manage a team of world-class developers to bring
              your vision to life
            </p>
            <button className="text-white mt-[10px] rounded-[8px] font-semibold text-[18px] bg-[#263238] px-[30px] py-[18px]">
              Explore More
            </button>
          </div>
        </div>
        <div className="w-1/2 max-md:w-full">
          <img src={Image2} className="h-340 w-764 rounded-[20px] animate-up-down"></img>
        </div>
      </div>
      <img src={Image3} className="h-139 w-522 bottom-0 left-0 absolute"></img>
    </section>
  );
}

export default Hero;
