import React, { useState } from "react";
import CodeIcon1 from "../Home/images/mon 1.png";
import CodeIcon2 from "../Home/images/kisspng-mysqli-php-database-linux-coding-5ac4824a2e87e0 1.png";

const Section5 = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <section class=" bg-[#f5f3f3] relative p-5">
      <div className="w-[69px] h-[5px] bg-[#FF9900] relative mt-5 mx-auto" />
      <p className="top-2 left-0 [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-dark-blue text-[35px] text-center tracking-[0] leading-[55px]">
        <span className="[font-family:'Inter-Regular',Helvetica] font-normal text-[#263238] text-[35px] tracking-[0] leading-[55px]">
          Our
          <br />
        </span>
        <span className="[font-family:'Inter-Bold',Helvetica] font-bold">
          Tech Stack
        </span>
      </p>
      <div className="container mx-auto px-4 pb-8">
        <div className="flex justify-center mb-8">
          <span
            className={`mr-4 py-2 px-4 text-xl rounded-md focus:outline-none cursor-pointer ${
              activeTab === 1 ? "text-orange-500 font-bold" : "text-gray-600"
            }`}
            onClick={() => handleTabClick(1)}
          >
            Frontend
          </span>
          <span
            className={`py-2 px-4 text-xl rounded-md focus:outline-none cursor-pointer ${
              activeTab === 2 ? "text-orange-500 font-bold" : "text-gray-600"
            }`}
            onClick={() => handleTabClick(2)}
          >
            Backend
          </span>
        </div>
        <div className="flex justify-center">
          {activeTab === 1 && (
            <div className="grid grid-cols-2 gap-4">
              <img src={CodeIcon1} alt="Image 1" className="w-full h-auto" />
              <img src={CodeIcon1} alt="Image 1" className="w-full h-auto" />
              <img src={CodeIcon1} alt="Image 1" className="w-full h-auto" />
              <img src={CodeIcon1} alt="Image 1" className="w-full h-auto" />
              <img src={CodeIcon1} alt="Image 1" className="w-full h-auto" />
              <img src={CodeIcon1} alt="Image 1" className="w-full h-auto" />
            </div>
          )}
          {activeTab === 2 && (
            <div className="grid grid-cols-2 gap-4">
              <img src={CodeIcon2} alt="Image 6" className="w-full h-auto" />
              <img src={CodeIcon2} alt="Image 6" className="w-full h-auto" />
              <img src={CodeIcon2} alt="Image 6" className="w-full h-auto" />
              <img src={CodeIcon2} alt="Image 6" className="w-full h-auto" />
              <img src={CodeIcon2} alt="Image 6" className="w-full h-auto" />
              <img src={CodeIcon2} alt="Image 6" className="w-full h-auto" />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Section5;
