import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import { Autoplay, Pagination } from "swiper/modules";
import "./Section2.css";
import slider1 from "./images/slider 1.svg";

function Section2() {
  return (
    <div className="relative bg-gray-200 pt-10 pb-5">
      <div className="h-[5px] w-[69px] mx-auto bg-[#FF9900]"></div>
      <div className="mt-[20px] text-center">
        <h1 className="text-[35px] leading-[55px]">Services</h1>
        <h1 className="text-[35px] leading-[55px]  mt-[0px] font-bold">
          We are offering
        </h1>
      </div>
      <Swiper
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        spaceBetween={50}
        loop={true}
        pagination={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="w-full h-full bg-orange custom-slide">
            <div className="top-[36px] left-[16px] inline-flex flex-col items-start gap-[20px] relative">
              <div className="flex-[0_0_auto] inline-flex flex-col items-start gap-[20px] relative">
                <div className="relative w-[58px] h-[58px] bg-white gray-50 rounded-[29px] border border-solid border-dark-orange">
                  <img
                    className="absolute w-[34px] h-[34px] top-[11px] left-[11px] object-cover"
                    alt="Code perspective"
                    src={slider1}
                  />
                </div>
                <div className="relative w-[271px] h-[25px] font-headings-headings-h4 font-[number:var(--headings-headings-h4-font-weight)] text-[#000000] text-[length:var(--headings-headings-h4-font-size)] tracking-[var(--headings-headings-h4-letter-spacing)] leading-[var(--headings-headings-h4-line-height)] whitespace-nowrap [font-style:var(--headings-headings-h4-font-style)]">
                  Software Testing Service
                </div>
              </div>
              <p className="relative w-[301px] font-paragraphs-default font-[number:var(--paragraphs-default-font-weight)] text-font text-[length:var(--paragraphs-default-font-size)] tracking-[var(--paragraphs-default-letter-spacing)] leading-[var(--paragraphs-default-line-height)] [font-style:var(--paragraphs-default-font-style)]">
                A Website is an extension of yourself and we can help you to
                express it properly. Your website is your number one marketing
                asset because we live in a digital age.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="w-full h-full bg-orange custom-slide">
            <div className="top-[36px] left-[16px] inline-flex flex-col items-start gap-[20px] relative">
              <div className="flex-[0_0_auto] inline-flex flex-col items-start gap-[20px] relative">
                <div className="relative w-[58px] h-[58px] bg-white gray-50 rounded-[29px] border border-solid border-dark-orange">
                  <img
                    className="absolute w-[34px] h-[34px] top-[11px] left-[11px] object-cover"
                    alt="Code perspective"
                    src={slider1}
                  />
                </div>
                <div className="relative w-[271px] h-[25px] font-headings-headings-h4 font-[number:var(--headings-headings-h4-font-weight)] text-[#000000] text-[length:var(--headings-headings-h4-font-size)] tracking-[var(--headings-headings-h4-letter-spacing)] leading-[var(--headings-headings-h4-line-height)] whitespace-nowrap [font-style:var(--headings-headings-h4-font-style)]">
                  Web Design & Development
                </div>
              </div>
              <p className="relative w-[301px] font-paragraphs-default font-[number:var(--paragraphs-default-font-weight)] text-font text-[length:var(--paragraphs-default-font-size)] tracking-[var(--paragraphs-default-letter-spacing)] leading-[var(--paragraphs-default-line-height)] [font-style:var(--paragraphs-default-font-style)]">
                A Website is an extension of yourself and we can help you to
                express it properly. Your website is your number one marketing
                asset because we live in a digital age.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="w-full h-full bg-orange custom-slide">
            <div className="top-[36px] left-[16px] inline-flex flex-col items-start gap-[20px] relative">
              <div className="flex-[0_0_auto] inline-flex flex-col items-start gap-[20px] relative">
                <div className="relative w-[58px] h-[58px] bg-white gray-50 rounded-[29px] border border-solid border-dark-orange">
                  <img
                    className="absolute w-[34px] h-[34px] top-[11px] left-[11px] object-cover"
                    alt="Code perspective"
                    src={slider1}
                  />
                </div>
                <div className="relative w-[271px] h-[25px] font-headings-headings-h4 font-[number:var(--headings-headings-h4-font-weight)] text-[#000000] text-[length:var(--headings-headings-h4-font-size)] tracking-[var(--headings-headings-h4-letter-spacing)] leading-[var(--headings-headings-h4-line-height)] whitespace-nowrap [font-style:var(--headings-headings-h4-font-style)]">
                  Mobile App Development
                </div>
              </div>
              <p className="relative w-[301px] font-paragraphs-default font-[number:var(--paragraphs-default-font-weight)] text-font text-[length:var(--paragraphs-default-font-size)] tracking-[var(--paragraphs-default-letter-spacing)] leading-[var(--paragraphs-default-line-height)] [font-style:var(--paragraphs-default-font-style)]">
                A Website is an extension of yourself and we can help you to
                express it properly. Your website is your number one marketing
                asset because we live in a digital age.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="w-full h-full bg-orange custom-slide">
            <div className="top-[36px] left-[16px] inline-flex flex-col items-start gap-[20px] relative">
              <div className="flex-[0_0_auto] inline-flex flex-col items-start gap-[20px] relative">
                <div className="relative w-[58px] h-[58px] bg-white gray-50 rounded-[29px] border border-solid border-dark-orange">
                  <img
                    className="absolute w-[34px] h-[34px] top-[11px] left-[11px] object-cover"
                    alt="Code perspective"
                    src={slider1}
                  />
                </div>
                <div className="relative w-[271px] h-[25px] font-headings-headings-h4 font-[number:var(--headings-headings-h4-font-weight)] text-[#000000] text-[length:var(--headings-headings-h4-font-size)] tracking-[var(--headings-headings-h4-letter-spacing)] leading-[var(--headings-headings-h4-line-height)] whitespace-nowrap [font-style:var(--headings-headings-h4-font-style)]">
                  Software Testing Service
                </div>
              </div>
              <p className="relative w-[301px] font-paragraphs-default font-[number:var(--paragraphs-default-font-weight)] text-font text-[length:var(--paragraphs-default-font-size)] tracking-[var(--paragraphs-default-letter-spacing)] leading-[var(--paragraphs-default-line-height)] [font-style:var(--paragraphs-default-font-style)]">
                A Website is an extension of yourself and we can help you to
                express it properly. Your website is your number one marketing
                asset because we live in a digital age.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="w-full h-full bg-orange custom-slide">
            <div className="top-[36px] left-[16px] inline-flex flex-col items-start gap-[20px] relative">
              <div className="flex-[0_0_auto] inline-flex flex-col items-start gap-[20px] relative">
                <div className="relative w-[58px] h-[58px] bg-white gray-50 rounded-[29px] border border-solid border-dark-orange">
                  <img
                    className="absolute w-[34px] h-[34px] top-[11px] left-[11px] object-cover"
                    alt="Code perspective"
                    src={slider1}
                  />
                </div>
                <div className="relative w-[271px] h-[25px] font-headings-headings-h4 font-[number:var(--headings-headings-h4-font-weight)] text-[#000000] text-[length:var(--headings-headings-h4-font-size)] tracking-[var(--headings-headings-h4-letter-spacing)] leading-[var(--headings-headings-h4-line-height)] whitespace-nowrap [font-style:var(--headings-headings-h4-font-style)]">
                  Software Testing Service
                </div>
              </div>
              <p className="relative w-[301px] font-paragraphs-default font-[number:var(--paragraphs-default-font-weight)] text-font text-[length:var(--paragraphs-default-font-size)] tracking-[var(--paragraphs-default-letter-spacing)] leading-[var(--paragraphs-default-line-height)] [font-style:var(--paragraphs-default-font-style)]">
                A Website is an extension of yourself and we can help you to
                express it properly. Your website is your number one marketing
                asset because we live in a digital age.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="w-full h-full bg-orange custom-slide">
            <div className="top-[36px] left-[16px] inline-flex flex-col items-start gap-[20px] relative">
              <div className="flex-[0_0_auto] inline-flex flex-col items-start gap-[20px] relative">
                <div className="relative w-[58px] h-[58px] bg-white gray-50 rounded-[29px] border border-solid border-dark-orange">
                  <img
                    className="absolute w-[34px] h-[34px] top-[11px] left-[11px] object-cover"
                    alt="Code perspective"
                    src={slider1}
                  />
                </div>
                <div className="relative w-[271px] h-[25px] font-headings-headings-h4 font-[number:var(--headings-headings-h4-font-weight)] text-[#000000] text-[length:var(--headings-headings-h4-font-size)] tracking-[var(--headings-headings-h4-letter-spacing)] leading-[var(--headings-headings-h4-line-height)] whitespace-nowrap [font-style:var(--headings-headings-h4-font-style)]">
                  Software Testing Service
                </div>
              </div>
              <p className="relative w-[301px] font-paragraphs-default font-[number:var(--paragraphs-default-font-weight)] text-font text-[length:var(--paragraphs-default-font-size)] tracking-[var(--paragraphs-default-letter-spacing)] leading-[var(--paragraphs-default-line-height)] [font-style:var(--paragraphs-default-font-style)]">
                A Website is an extension of yourself and we can help you to
                express it properly. Your website is your number one marketing
                asset because we live in a digital age.
              </p>
            </div>
          </div>
        </SwiperSlide>
        {/* <SwiperSlide>
          <div className="w-full h-full bg-orange custom-slide"></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="w-full h-full bg-orange custom-slide"></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="w-full h-full bg-orange custom-slide"></div>
        </SwiperSlide> */}
      </Swiper>
    </div>
  );
}

export default Section2;
